import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function About() {
  return (
    <Layout nav="about">
      <SEO title="关于轻派" />
      <section className="py-4" style={{ backgroundColor: '#293D5D' }}>
        <div className="container mx-auto px-4 sm:px-16 flex items-center justify-between">
          <div>
            <h1 className="text-3xl sm:text-4xl text-white">关于轻派</h1>
            <h2 className="text-xs text-white opacity-60">About Qingpai</h2>
          </div>
          <img src={require('../assets/87d288.png')} alt="图片" className="h-40" />
        </div>
      </section>

      <section className="bg-white py-8 md:py-24">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
          <div className="flex-1">
            <p className="mb-4 text-base text-gray-900" style={{ textIndent: '2em' }}>
              杭州轻派科技有限公司位于杭州互联网科创核心区，团队具有多年从事互联网医疗项目策划、研发、运营经验，公司依托温州医科大学眼视光创新团队、浙江省疾控中心，以及浙江大学未来医学研究中心的科研合作支持，为中国眼视光与眼科提供产业互联网服务，实现{' '}
              <strong className="text-primary">高效发现患者和有效服务患者，构建基础眼保健信息化体系</strong>。
            </p>
            <p className="mb-4 text-base text-gray-900" style={{ textIndent: '2em' }}>
              现已推出多个场景的眼健康筛查系统，包括0-6岁儿童眼保健及视力检查系统、儿童青少年近视防控平台系统、学生健康状况综合监测评价系统、居民眼健康检查系统，累计已完成5000余万人次眼健康数据采集，即{' '}
              <strong className="text-primary">发现患者，构建眼保健数据基础</strong>
              ；并推出轻派眼健康管理系统、眼科远程门诊系统等，为视光门店、医院赋能智慧化管理、AI诊疗与远程医疗服务，实现眼健康患者精准分流及行业优质资源有效利用，即
              <strong className="text-primary">服务患者，构建中国基础眼保健医学信息基础</strong>。
            </p>
            <p className="mb-4 text-base text-gray-900" style={{ textIndent: '2em' }}>
              轻派科技现已取得软件著作权十余项，并已入选杭州市高新科技重点扶持企业“雏鹰计划”、浙江省科技型企业、软件型企业认定等，已成为中国眼健康及学校卫生领域信息化领先的头部企业。
            </p>
          </div>
          <div className="md:ml-8 max-w-sm">
            <img src={require('../assets/c6a26b.png')} alt="图片" />
          </div>
        </div>
      </section>

      <section className="bg-white py-8">
        <div className="container mx-auto px-4 text-center">
          <header className="flex flex-col items-center justify-center">
            <h2 className="text-gray-900 text-xl font-medium">科研合作支持</h2>
            <div className="w-12 h-1 bg-primary"></div>
          </header>
          <div className="py-8 grid grid-cols-3 gap-4">
            <img src={require('../assets/d654ca.png')} alt="图片" className="transition hover:shadow-xl" />
            <img src={require('../assets/92ceb8.png')} alt="图片" className="transition hover:shadow-xl" />
            <img src={require('../assets/143039.png')} alt="图片" className="transition hover:shadow-xl" />
          </div>
        </div>
      </section>
    </Layout>
  );
}
